<template>
  <div class="xianYuOrder">
    <Top name="确认订单" email back></Top>
    <div v-if="data.data">
      <div class="goods">
        <div
          class="goods_list"
          v-for="(item, index) in data.data.data"
          :key="index"
        >
          <div class="goods_img">
            <img
              src="../../assets/images/itemicon/18/101002.png"
              alt="图片"
            />
          </div>
          <div class="goods_content">
            <p style="font-size: 0.9rem">无限刀\仙玉 {{ item.item_num }}万</p>
            <span style="color: #808080">{{ item.server_name }}</span
            ><br />
            <span class="span_cl">￥{{ item.price }}</span>
          </div>
          <div class="goods_right">
            <img
              src="../../assets/images/authentication.png"
              alt="开发商认证"
            /><br />
            <span>{{ item.surplus_time }}</span>
          </div>
        </div>
      </div>
      <div class="goods_detail">
        <p>
          合计仙玉 <span class="span_cl span_flo_r">{{ data.item_num }}万</span>
        </p>
        <p>
          预设仙玉 <span class="span_flo_r">{{ data.presets_coin }}万</span>
        </p>
        <p>
          订单数量 <span class="span_flo_r">{{ data.order_num }}笔</span>
        </p>
        <p>
          剩余预设 <span class="span_flo_r">{{ data.surplus_coin }}万</span>
        </p>
        <p>
          平均单价
          <span class="span_flo_r">1元≈{{ data.average_proportion }}仙玉</span>
        </p>
      </div>
      <div class="bad_scalp">
        为避免恶意刷单，组合支付仅限制10个订单数，组合预制额度超出10个订单数，系统将不算在组合支付中
      </div>

      <div class="price_tag">
        <span class="price_tag_word">应付金额：</span>
        <span class="orange">￥{{ data.money }}</span>
        <span class="count_time" v-show="is_count_time"
          >{{ count_time_s }} 秒</span
        >
        <!-- <van-count-down :time="count_time_s" format="DD 天 HH 时 mm 分 ss 秒" v-show="is_count_time" /> -->
        <span class="count_time" v-show="!is_count_time">支付超时</span>
        <van-button
          class="purchase_btn"
          round
          color="linear-gradient(to right, #FF704D, #F73727)"
          :color="is_count_time ? 'linear-gradient(to right, #FF704D, #F73727)' : '#7f7f7f'"
          @click="payClick"
          size="large"
          >立即购买</van-button
        >
      </div>
    </div>
    <van-dialog v-model="equipShow" show-cancel-button @confirm="equipConfirm">
      <div class="equip_w">
        <van-form>
          <van-field
            readonly
            clickable
            name="picker"
            :value="currencyForm.region"
            label="收货大区"
            placeholder="请选择"
            @click="changeClick(1)"
          />
          <van-popup
            v-model="currencyForm.gamePicker"
            position="bottom"
            duration="0.15"
            get-container="body"
          >
            <van-picker
              show-toolbar
              value-key="regione_name"
              :columns="currencyForm.regions"
              @confirm="onConfirm"
              @cancel="currencyForm.gamePicker = false"
            />
          </van-popup>
          <van-field
            readonly
            clickable
            name="picker"
            :value="currencyForm.server"
            label="收货区服"
            placeholder="请选择"
            @click="changeClick(2)"
          />
          <van-popup
            v-model="currencyForm.serverPicker"
            position="bottom"
            duration="0.15"
            get-container="body"
          >
            <van-picker
              show-toolbar
              value-key="server_name"
              :columns="currencyForm.servers"
              @confirm="onConfirm"
              @cancel="currencyForm.serverPicker = false"
            />
          </van-popup>
          <van-field
            readonly
            clickable
            name="picker"
            :value="currencyForm.role"
            label="收货角色"
            placeholder="请选择"
            @click="changeClick(3)"
          />
          <van-popup
            v-model="currencyForm.rolePicker"
            position="bottom"
            duration="0.15"
            get-container="body"
          >
            <van-picker
              show-toolbar
              value-key="chrname"
              :columns="currencyForm.roles"
              @confirm="onConfirm"
              @cancel="currencyForm.rolePicker = false"
            />
          </van-popup>
        </van-form>
      </div>
    </van-dialog>

    <!-- 选择支付方式 -->
    <van-dialog
      class="choosePay"
      v-model="payshow"
      :show-confirm-button="false"
    >
      <div class="close"><i @click="payshow = false"></i></div>
      <div class="title">选择支付方式</div>
      <ul class="choosePay_list">
        <li @click="choosePya('1')">
          <img src="../../assets/images/alipay_pay.png" />
          <div class="blue">支付宝支付</div>
        </li>
        <li @click="choosePya('2')">
          <img src="../../assets/images/yue.png" />
          <div>
            <div class="orange">余额支付</div>
            <div class="yue">
              <span>账户可用余额为：</span>
              <span class="orange">{{ accountBalance }}元</span>
            </div>
          </div>
        </li>
      </ul>
    </van-dialog>

    <van-dialog
      class="choosePay password_input"
      v-model="input_show"
      title=""
      :show-confirm-button="false"
    >
      <div class="close"><i @click="input_show = false"></i></div>
      <div class="title">请输入支付密码</div>
      <van-password-input
        :value="password"
        :focused="showKeyboard"
        @focus="showKeyboard = true"
      />
      <!-- <div class="password_forget">
                <router-link :to="{ name: 'Passwordset', params: { active: 1}}"><span class="blue">忘记密码</span></router-link>
            </div> -->
      <div class="affirm_pay">
        <van-button
          :disabled="password.length !== 6"
          round
          color="linear-gradient(to right, rgb(255, 112, 77), rgb(247, 55, 39))"
          size="large"
          @click="confirm_payment"
          >确认支付</van-button
        >
      </div>
    </van-dialog>
    <van-number-keyboard
      v-model="password"
      :show="showKeyboard"
      :maxlength="6"
      @blur="showKeyboard = false"
    />
  </div>
</template>

<script>
import Top from "@/components/top";
import { debounce } from "@/assets/js/utils.js";
import { Dialog } from "vant";
export default {
  name: "XianYuOrder",
  components: {
    Top,
  },
  data() {
    return {
      //倒计时
      count_time_s: "", 
      isDownTime: "",
      is_count_time: true,
      data: "",
      goods_str: "",
      paymoney: "",
      input_show: false,
      equipShow: false,
      payshow: false,
      showKeyboard: false,
      password: "",
      currencyForm: {
        channelId: "",
        region: "",
        regione_id: "",
        regions: [],
        gamePicker: false,
        server: "",
        server_id: "",
        serverPicker: false,
        servers: [],
        role: "",
        seedname: "",
        rolePicker: false,
        roles: [],
      },
      accountBalance: "",
      frozenBalance: "",
      isSet: false,
      debounce,
      // surplus_coin: this.$route.query.num,
    };
  },
  created() {
    //获取home页路由参数
    this.data = JSON.parse(this.$route.query.data);
    this.getOrNoLock();
    this.getBalance();
    //防抖动
    this.click = this.debounce(this.payRequest, 500);
    //清除支付宝支付留下的元素
    let divnewForm = document.getElementsByTagName("divnewform");
    // console.log(divnewForm)
    if (divnewForm.length) {
      document.body.removeChild(divnewForm[0]);
    }
    this.ip = localStorage.getItem("ip");
    // console.log(this.ip)
    // let isPay = localStorage.getItem("isPay");
    // console.log(window.history.length)
    // if (isPay == "true" || window.history.length <= 2) {
      // console.log(223332)
      // this.checkOrder();
    // }
    // console.log(this.data);
  },
  methods: {
    //获取是否被锁单数据
    getOrNoLock() {
      let arr = this.data.data.data.map((item) => {
        return item.goods_id;
      });
      this.goods_str = arr.toString();
      let params = { goods_str: this.goods_str };
      this.$api.pay.checkOrders(params).then((res) => {
        this.count_time_s = res.data.count_down;
        // this.count_time_s = 10;

        this.isDownTime = setInterval(() => {
          this.count_time_s--;
          // console.log(this.count_time_s);
          if (this.count_time_s < 1) {
            this.is_count_time = false;
            clearInterval(this.isDownTime);
            this.$toast({
              message: "订单锁定超时",
              duration: 1500,
            });
            Dialog.alert({
              title: "温馨提示",
              message: "订单支付超时，请重新选择仙玉金额",
            }).then(() => {
              this.$router.push({
                path: "/",
              });
            });
          }
        }, 1000);
      });
    },
    //立即支付按钮
    payClick() {
      if (this.is_count_time) {
        let params = { goods_str: this.goods_str };
        this.$api.pay.checkOrders(params).then((res) => {
          console.log(res);
          if (res.data.is_lock == 1) {
            this.regionList();
            this.equipShow = true;
          }
        });
      } else {
        Dialog.alert({
          title: "温馨提示",
          message: "订单支付超时，请重新选择仙玉金额",
        }).then(() => {
          this.$router.push({
            path: "/",
          });
        });
      }
    },

    // 确认支付按钮
    confirm_payment() {
      // console.log(this.paymoney)
      this.$toast({
        duration: 1500,
        message: "支付中······",
      });
      this.$api.shopping
        .checkPayPassword({
          payPw: this.password,
        })
        .then((res) => {
          if (res.code == 200) {
            // console.log(this.paymoney)
            //
            if (this.paymoney == 0) {
              //  余额支付
              this.paySubmit();
            } else {
              this.$toast({
                duration: 1500,
                message: "余额不足",
              });
              return false;
              // 混合支付
              let data = {
                  number: this.paymoney,
                  checked: 1,
                  goods_str: this.goods_str,
                  is_combination: 1,
                },
                dataObj = {};
              // console.log(data)
              if (this.appData.server_id) {
                dataObj = data;
                dataObj.to_server = this.currencyForm.server_id;
                dataObj.chrname = this.currencyForm.role;
              } else {
                dataObj = data;
              }
              // console.log(dataObj)
              this.$router.push({
                name: "paymentChannels",
                query: dataObj,
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //查看支付是否成功
    checkOrder() {
      //每2s查询一次，直到获取到结果为止
      let timer;
      // console.log(123)
      timer = setInterval(() => {
        this.$api.shopping
          .checkOrder({
            goods_str: this.goods_str,
            is_combination: 1,
          })
          .then((res) => {
            //支付宝支付拿到结果后结束轮询
            clearInterval(timer);
            //针对微信支付拿到结果后清除存的值
            localStorage.removeItem("isPay");
            if (res.code == 200) {
              this.$toast({
                duration: 1500,
                message: "购买成功",
              });
              this.$router.push({
                path: "/",
                query: {
                  downHandel: 1,
                },
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 2000);
    },
    //余额支付
    paySubmit() {
      // let isbalance = this.checked ? 1 : 0,
      let data = {
          username: localStorage.getItem("username"),
          goods_str: this.goods_str,
          pay_type: "ali",
          type: "wap",
          is_balance: 1,
          is_combination: 1,
        },
        dataObj = {};
      if (this.currencyForm.server_id) {
        dataObj = data;
        dataObj.to_server = this.currencyForm.server_id;
        dataObj.chrname = this.currencyForm.role;
      } else {
        dataObj = data;
      }
      this.$api.shopping
        .createOrderAli(dataObj)
        .then((res) => {
          if (res.code == 200) {
            //this.$toast.success('支付成功');
            this.input_show = false;
            // this.$router.push({
            //     name: 'Paysuccess',
            // });
            this.$toast({
              duration: 1500,
              message: "购买成功",
            });
            this.$router.push({
              path: "/",
              query: {
                downHandel: 1,
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //获取账户余额
    getBalance() {
      let username = localStorage.getItem("username");
      this.$api.account
        .getBalance({ username: username })
        .then((res) => {
          if (res.code == 200) {
            this.accountBalance = res.data.accountBalance;
            this.frozenBalance = res.data.frozenBalance;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //判断是否已安全设置
    isSettig() {
      this.$api.account.isPaymentCode({ type: 1 }).then((res) => {
        if (res.code == 200) {
          if (res.data.length == 0) {
            this.isSet = false;
          } else {
            this.isSet = true;
          }
        }
      });
    },
    // 选择付款方式
    choosePya(type) {
      if (type == 1) {
        // 支付宝支付
        this.click();
        // this.$toast({
        //   duration: 1500,
        //   message: "暂未开通支付宝支付功能",
        // });
      } else {
        // 余额支付
        this.$api.account
          .getBalance({ username: localStorage.getItem("username") })
          .then((res) => {
            if (res.code == 200) {
              if (res.data.is_set_up == 0) {
                this.isSet = false;
                this.$toast({
                  duration: 1500,
                  message: "为保证您的账户安全，请先完成安全设置哦！",
                });
                this.$router.push("/safesetting");
              } else {
                this.isSet = true;
                // this.showKeyboard = true;//弹出键盘
                this.getMoney();
              }
            }
          });
      }
    },

    //提交支付请求
    payRequest() {
      // //微信支付成功后会新窗口打开页面，所以用本地存值的方法来判断是否发起订单结果请求
      localStorage.setItem("isPay", true);
      // let isbalance;
      // isbalance =  0;
      //支付宝支持成功后会返回到原界面，所以支付后原界面需要通过轮询的方法来判断是否发起订单结果请求
      let param, contents, tok;
      tok = localStorage.getItem("token").substring(7);
      if (this.currencyForm.server_id) {
        // console.log(111)
        param = {
          username: localStorage.getItem("username"),
          goods_str: this.goods_str,
          is_combination: 1,
          pay_type: "alipay",
          type: "wap",
          is_balance: 0,
          token: tok,
          to_server: this.currencyForm.server_id,
          chrname: this.currencyForm.role,
        };
      } else {
        // console.log(222)
        param = {
          username: localStorage.getItem("username"),
          goods_str: this.goods_str,
          is_combination: 1,
          pay_type: "alipay",
          type: "wap",
          is_balance: 0,
          token: tok,
        };
      }
      // console.log(param,this.detailData)
      // return
      // console.log(param)
      // return
      contents = this.$Base64.encode(JSON.stringify(param));
      this.$api.shopping
        .createOrderAli({
          content: contents,
        })
        .then((res) => {
          // console.log(res);
          // return false
          //存放原始数据
          let divForm = document.getElementsByTagName("divform");

          if (divForm.length) {
            document.body.removeChild(divForm[0]);
          }
          //存放提取出来的form表单
          let divnewForm = document.getElementsByTagName("divnewform");
          if (divnewForm.length) {
            document.body.removeChild(divnewForm[0]);
          }
          const div = document.createElement("divform");
          div.innerHTML = res; // data就是接口返回的form 表单字符串
          document.body.appendChild(div);
          //将提取出来的form元素放到新元素里
          let divs = document.createElement("divnewform");
          divs.innerHTML = "";
          divs.appendChild(document.forms[0]);
          document.body.appendChild(divs);
          // console.log(divs);
          // return
          //删除原始元素
          // document.body.removeChild(divForm[0]);
          //console.log(document.forms[0]);
          //document.forms[0].setAttribute('target', '_blank'); // 新开窗口跳转
          document.forms[0].submit();

          // setTimeout(() => {
          //     this.checkOrder();
          // }, 800);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //计算需要支付的金额
    getMoney() {
      //开启抵扣;
      this.$api.shopping
        .ableAllPay({
          goods_str: this.goods_str,
        })
        .then((res) => {
          if (res.code == 200) {
            if (res.data.able_all_balance_pay == 1) {
              this.paymoney = 0;
            } else {
              this.paymoney = this.accSub(this.data.money, this.accountBalance);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
      this.input_show = true;
      this.payshow = false;
    },
    //精确计算小数减法结果
    accSub(arg1, arg2) {
      if (isNaN(arg1)) {
        arg1 = 0;
      }
      if (isNaN(arg2)) {
        arg2 = 0;
      }
      arg1 = Number(arg1);
      arg2 = Number(arg2);

      var r1, r2, m, n;
      try {
        r1 = arg1.toString().split(".")[1].length;
      } catch (e) {
        r1 = 0;
      }
      try {
        r2 = arg2.toString().split(".")[1].length;
      } catch (e) {
        r2 = 0;
      }
      m = Math.pow(10, Math.max(r1, r2)); //last modify by deeka //动态控制精度长度
      n = r1 >= r2 ? r1 : r2;
      return ((arg1 * m - arg2 * m) / m).toFixed(n);
    },
    //弹框确认
    equipConfirm() {
      if (
        this.currencyForm.region &&
        this.currencyForm.server &&
        this.currencyForm.role
      ) {
        this.payshow = true;
        this.equipShow=false
      } else {
        return false;
      }
    },
    //选择框确认
    onConfirm(value) {
      if (this.currencyForm.gamePicker) {
        this.currencyForm.region = value.regione_name;
        this.currencyForm.regione_id = value.regione_id;
        this.currencyForm.server = "";
        this.currencyForm.servers = [];
        this.currencyForm.role = "";
        this.currencyForm.roles = [];
        this.currencyForm.gamePicker = false;
        this.state = 2;
        this.serverList();
      } else if (this.currencyForm.serverPicker) {
        this.currencyForm.server = value.server_name;
        this.currencyForm.server_id = value.server_id;
        this.currencyForm.role = "";
        this.currencyForm.roles = [];
        this.currencyForm.serverPicker = false;
        this.state = 2;
        this.roleList();
      } else {
        this.currencyForm.role = value.chrname;
        this.currencyForm.seedname = value.seedname;
        this.currencyForm.rolePicker = false;
      }
    },
    //根据渠道获取大区列表
    regionList() {
      this.$api.shopping
        .regionList({
          channel_id: this.data.data.data[0].channel_id,
        })
        .then((res) => {
          this.isLoading = false;
          if (res.code == 200) {
            this.currencyForm.regions = res.data;
            //首次点开默认值
            // console.log(this.state)
            if (this.state == 1) {
              this.currencyForm.channelId = 18;
              if (this.currencyForm.channelId) {
                this.serverList();
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //根据渠道获取区服列表
    serverList() {
      this.$api.helper
        .getServerList({
          channel_id: this.data.data.data[0].channel_id,
        })
        .then((res) => {
          console.log("区服列表", res);
          if (res.code == 200) {
            for (var key in res.data) {
              this.currencyForm.servers.push({
                server_name: res.data[key].server_name,
                server_id: res.data[key].server_id,
              });
            }
            //首次点开默认值
            // if (this.state == 1) {
            //   this.roleList(this.currencyForm);
            // }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //根据区服获取角色列表
    roleList() {
      this.$api.helper
        .getPlayerList({
          channel_id: this.data.data.data[0].channel_id,
          server_id: this.currencyForm.server_id,
          // type: 1,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.currencyForm.roles = res.data;
            //首次点开默认值
            // if (this.state == 1) {
            //     this.currencyForm.role = this.detailData.nickname;
            // }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //选择框选择顺序提示
    changeClick(key) {
      //this.isToken();
      if (!localStorage.getItem("token")) {
        return false;
      }
      if (key == 2) {
        if (this.currencyForm.region) {
          this.currencyForm.serverPicker = true;
        } else {
          this.$toast({
            message: "请先选择大区，再选区服",
            duration: 1500,
          });
        }
      } else if (key == 3) {
        if (this.currencyForm.server) {
          this.currencyForm.rolePicker = true;
        } else {
          this.$toast({
            message: "请先选择区服，再选角色",
            duration: 1500,
          });
        }
      } else if (key == 1) {
        this.currencyForm.gamePicker = true;
      }
    },
  },
  beforeDestroy() {
    this.debounce = null; //清除闭包
    // console.log('销毁了')
    //销毁倒计时
    clearInterval(this.isDownTime);
  },
};
</script>

<style scoped>
.xianYuOrder {
  width: 100%;
  height: 100%;
  background-color: #f4f3f3;
 overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
  box-sizing: border-box;
}
.xianYuOrder .span_cl {
  color: #ff4e00;
}
.xianYuOrder .span_flo_r {
  float: right;
}
.goods {
  width: 100%;
  background-color: #f4f3f3;
  padding-bottom: 15.2167rem;
  
}
.goods .goods_list {
  width: 100%;
  height: 6.25rem;
  background-color: #fff;
  padding: 0 0.725rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.2125rem;
}
.goods_list .goods_img {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.goods_img > img {
  display: block;
  width: 4.2rem;
  height: 3.5833rem;
}
.goods_list .goods_content {
  width: 50%;
  text-align: left;
  line-height: 1.2583rem;
  padding-left: 0.4167rem;
}
.goods .goods_right {
  padding-left: 0.9333rem;
  width: 31%;
  text-align: left;
}
.goods_right > img {
  display: block;
  width: 5.6667rem;
  height: 1.8542rem;
  margin-left: 0.4167rem;
}
.goods_detail {
  width: 100%;
  padding: 0.25rem 0.725rem;
  background-color: #fff;
  position: fixed;
  bottom: 5.2rem
}
.goods_detail p {
  width: 100%;
  text-align: left;
  line-height: 1.4583rem;
  
}
.xianYuOrder .bad_scalp {
  /* height: 1.725rem; */
  font-size: 0.6rem;
  color: #e02d43;
  background: #f4f3f3;
  position: fixed;
  bottom: 3.125rem;
  left: 0;
  text-align: left;
  /* width: 94%; */
  padding: 2px 11px;
}
.xianYuOrder .price_tag {
  width: 100%;
  height: 3.125rem;
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  text-align: right;
  padding: 0.5rem 16px 0.4rem;
}
.price_tag .purchase_btn {
  width: 5.625rem;
  height: 2.125rem;
  vertical-align: middle;
  margin: 0;
  float: right;
}
.price_tag .count_time {
  font-size: 14px;
  line-height: 35px;
  margin-right: 10px;
}
.price_tag .price_item {
  float: left;
  margin-right: 0.6rem;
  text-align: center;
}
.price_tag .price_item:first-child {
  width: 2.25rem;
}
.price_tag .price_item a {
  display: block;
}
.price_tag .price_item img {
  width: 1.2rem;
}
.price_tag .price_item span {
  color: #808080;
}
.pop_wrap .pop_w p:first-child {
  text-align: center;
  margin-bottom: 0.5rem;
}
.pop_wrap .pop_w .on_price {
  margin: 0.5rem 0;
}
.lightgray {
  color: #b7b7b7;
}
.price_tag .orange {
  font: 14px/34px Open Sans, -apple-system, BlinkMacSystemFont, Helvetica Neue,
    Helvetica, Segoe UI, Arial, Roboto, PingFang SC, miui, Hiragino Sans GB,
    Microsoft Yahei, sans-serif;
  vertical-align: middle;
  float: left;
  font-weight: bold;
}
.price_tag .price_tag_word {
  font: 14px/34px Open Sans, -apple-system, BlinkMacSystemFont, Helvetica Neue,
    Helvetica, Segoe UI, Arial, Roboto, PingFang SC, miui, Hiragino Sans GB,
    Microsoft Yahei, sans-serif;
  color: #323233;
  vertical-align: middle;
  float: left;
}
.equip_w {
  padding: 4% 2% 3%;
}
.equip_w p {
  font-size: 13px;
}
.choosePay .close {
  height: 0.9792rem;
  position: relative;
  margin-top: 0.7083rem;
}
.choosePay .close i {
  position: absolute;
  background: url("../../assets/images/close.png") no-repeat;
  background-size: 100% 100%;
  width: 1rem;
  height: 0.9792rem;
  top: 0;
  right: 0.75rem;
}
.choosePay .title {
  font-size: 1.0208rem;
  color: #313131;
  padding-bottom: 1.0208rem;
  border-bottom: 0.0417rem solid #b3b3b3;
}
.choosePay_list {
  padding: 1.7708rem 0 0.4167rem;
}
.choosePay_list li {
  display: flex;
  justify-content: space-between;
  width: 88%;
  margin: 0 auto 1.4167rem;
  height: 4.1042rem;
  background: #efefef;
  border-radius: 0.5rem;
  align-items: center;
  border: 0.0417rem solid #d8d8d8;
}
.choosePay_list li img {
  width: 1.9375rem;
  height: 1.9375rem;
  margin-left: 1.1042rem;
}
.choosePay_list li > div {
  width: 86%;
  text-align: center;
  font-size: 1.0833rem;
}
.blue {
  color: #19a0e5;
}
.choosePay_list .yue {
  font-size: 0.8542rem;
  color: #808080;
}

.password_input .close {
  text-align: right;
  font: 0.875rem/1.3125rem Open Sans, -apple-system, BlinkMacSystemFont,
    Helvetica Neue, Helvetica, Segoe UI, Arial, Roboto, PingFang SC, miui,
    Hiragino Sans GB, Microsoft Yahei, sans-serif;
  padding-top: 0.3125rem;
}
.password_input .close i {
  margin-right: 0.3375rem;
}
.password_border {
  border-radius: 0.84375rem;
  background: #efefef;
}
.password_input .password_text {
  font: 0.9375rem /1.875rem Open Sans, -apple-system, BlinkMacSystemFont,
    Helvetica Neue, Helvetica, Segoe UI, Arial, Roboto, PingFang SC, miui,
    Hiragino Sans GB, Microsoft Yahei, sans-serif;
  color: #313131;
  border-bottom: 1px solid #b3b3b3;
}
.password_input .password_sum {
  font: 900 1.34375rem/1.84375rem Open Sans, -apple-system, BlinkMacSystemFont,
    Helvetica Neue, Helvetica, Segoe UI, Arial, Roboto, PingFang SC, miui,
    Hiragino Sans GB, Microsoft Yahei, sans-serif;
  margin-top: 0.9375rem;
}
.password_input .password_deduction {
  font: 0.71875rem/1.21875rem Open Sans, -apple-system, BlinkMacSystemFont,
    Helvetica Neue, Helvetica, Segoe UI, Arial, Roboto, PingFang SC, miui,
    Hiragino Sans GB, Microsoft Yahei, sans-serif;
  padding-bottom: 0.71875rem;
  border-bottom: 1px dashed #b3b3b3;
  margin-bottom: 0.96875rem;
}
.van-password-input {
  padding-top: 3.0625rem;
}
.affirm_pay {
  width: 12.5rem;
  margin: 1.8125rem auto 1.9167rem;
}
.van-password-input__security li {
  background: #f0f0f0;
  margin-right: 0.1875rem;
}
.password_input .password_forget {
  font: 0.98125rem/2.71875rem Open Sans, -apple-system, BlinkMacSystemFont,
    Helvetica Neue, Helvetica, Segoe UI, Arial, Roboto, PingFang SC, miui,
    Hiragino Sans GB, Microsoft Yahei, sans-serif;
  color: #227eeb;
  text-align: right;
  padding-right: 1rem;
}
.van-number-keyboard {
  z-index: 200004;
}
/* .van-hairline--surround::after{
    border: 0;
} */
</style>
<style>
.choosePay .van-hairline--surround::after {
  border: 0;
}
</style>
